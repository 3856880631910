<template>
  <section class="elv-accounts-defi-position-container">
    <AccountScreening
      ref="deFiPositionScreeningRef"
      tableType="DefiPosition"
      :exportText="$t('button.exportTreasuryAccount')"
      :filterTotal="deFiPositionFilter?.total || 0"
      @onChangeDeFiPositionFilter="onChangeDeFiPositionFilter"
      @onChangeAccountFilter="onChangeDeFiPositionFilter"
      @onOpenFilterOverlay="onOpenFilterOverlay"
    >
    </AccountScreening>
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.defiPosition" />
    <div class="elv-accounts-defi-position-table-container">
      <TableList
        ref="processTableRef"
        type="accounts"
        groupName="entityAccount"
        height="100%"
        :dateTime="deFiPositionScreeningRef?.dateTimeValue"
      />
    </div>
    <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />

    <FilterOverlay ref="filterOverlayRef" @onFilterData="onChangeDeFiPositionFilter" />
  </section>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { $t } from '@/i18n'
import TableList from './components/TableList.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { exportProgressStatusType } from '@/config/global'
import AccountScreening from '../components/Screening.vue'
import FilterOverlay from './components/FilterOverlay.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { DeFiHoldingPositionFilterType } from '#/AccountsTypes'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'

const route = useRoute()

const entityStore = useEntityStore()
const accountStore = useAccountStore()
const tableConfigStore = useReportsTableConfigStore()
const processTableRef = useTemplateRef('processTableRef')
const filterOverlayRef = useTemplateRef('filterOverlayRef')
const deFiPositionScreeningRef = useTemplateRef<InstanceType<typeof AccountScreening>>('deFiPositionScreeningRef')

const entityId = computed(() => {
  return route.params?.entityId as string
})

const deFiPositionFilter = computed(() => {
  return (
    accountStore.deFiPositionsFilterList.find((item) => {
      return item.entityId === entityId.value
    }) ?? ({} as DeFiHoldingPositionFilterType)
  )
})

const onOpenFilterOverlay = () => {
  filterOverlayRef.value?.onCheckDrawerStatus()
}

const onChangeDeFiPositionFilter = async () => {
  try {
    const date = deFiPositionScreeningRef.value?.dateTimeValue
    const subGroup = deFiPositionScreeningRef.value?.subGroup
    const group = deFiPositionScreeningRef.value?.group
    const params: any = {
      ...deFiPositionFilter.value?.data,
      date,
      group,
      subGroup
    }
    await tableConfigStore.getProcessTableConfig('deFiPosition', [], 'right', params)
    processTableRef.value?.resetList()
  } catch (error: any) {
    console.log(error)
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-accounts-defi-position') {
      try {
        accountStore.deFiHoldingFilterDataInit(entityId.value)
        tableConfigStore.processTableConfig = []
        let params = {
          date: dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'day').format('YYYY-MM-DD'),
          subGroup: 'PROTOCOL',
          group: 'ENTITY_ACCOUNT'
        }
        params = { ...params, ...deFiPositionFilter.value?.data }
        await tableConfigStore.getProcessTableConfig('deFiPosition', [], 'right', params)
        nextTick(() => {
          processTableRef.value?.resetList()
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  tableConfigStore.processTableConfig = []
  accountStore.agGridApi = null
})
</script>

<style lang="scss" scoped>
.elv-accounts-defi-position-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-accounts-defi-position-table-container {
    width: 100%;
    flex: 1;
    min-height: 0px;
    position: relative;
  }
}

:deep(.elv-financial-process-table) {
  padding-top: 0px;
}

.elv-accounts-header-main-right-add {
  position: absolute;
  right: 24px;
  top: -41px;
}
</style>
