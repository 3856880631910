<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-defi-position-filter-overlay"
    class="elv-defi-position-drawer-filter"
    :before-close="onCheckDrawerStatus"
    :size="360"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-defi-position-drawer-filter-header__title">
        {{ t('common.filter') }}
      </h4>
      <div class="elv-defi-position-drawer-filter-header-operating">
        <div class="elv-defi-position-drawer-filter-header__clear">
          <SvgIcon name="reset" width="14" height="14" class="elv-defi-position-drawer-filter-header__close" />
          <span @click="onClearData('')">{{ t('button.clear') }}</span>
        </div>
        <SvgIcon
          name="close"
          width="20"
          height="20"
          class="elv-defi-position-drawer-filter-header__close"
          @click="onCheckDrawerStatus"
        />
      </div>
    </template>
    <el-scrollbar v-loading="loading" wrap-class="elv-transaction-drawer-filter-wrap">
      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('common.account') }}</p>
          <span v-show="filterData?.entityAccountIds?.length" @click="onClearData('account')">{{
            t('button.clear')
          }}</span>
        </div>
        <ElvSelect
          v-model="filterData.entityAccountIds"
          width="312px"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :placeholder="placeholderText"
          :class="{ 'is-unEmpty': filterData?.entityAccountIds?.length }"
          :options="accountNormalOptions"
          :collapse-list="accountDeletedOptions"
          @clear="onClearData('account')"
        />
      </div>

      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('common.protocol') }}</p>
          <span v-show="filterData?.defiHoldingProtocolIds?.length" @click="onClearData('protocol')">{{
            t('button.clear')
          }}</span>
        </div>
        <ElvSelect
          v-model="filterData.defiHoldingProtocolIds"
          width="312px"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :placeholder="placeholderText"
          :class="{ 'is-unEmpty': filterData?.defiHoldingProtocolIds?.length }"
          :options="protocolOptions"
          @clear="onClearData('protocol')"
        />
      </div>

      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('common.type') }}</p>
          <span v-show="filterData?.typeList?.length" @click="onClearData('type')">{{ t('button.clear') }}</span>
        </div>
        <ElvSelect
          v-model="filterData.typeList"
          width="312px"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :placeholder="placeholderText"
          :class="{ 'is-unEmpty': filterData?.typeList?.length }"
          :options="typeOptions"
          @clear="onClearData('type')"
        />
      </div>
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        type="primary"
        round
        width="204"
        height="36"
        class="elv-defi-position-drawer-filter-footer__save"
        @click="onSave"
      >
        {{ t('button.apply') }}
      </elv-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { DeFiHoldingPositionParamsType } from '#/AccountsTypes'

const { t } = useI18n()
const route = useRoute()

const accountStore = useAccountStore()

const isShowEditDrawer = ref(false)
const saveLoading = ref(false)
const loading = ref(false)

const emit = defineEmits(['onFilterData'])

const filterData: any = ref({
  entityAccountIds: [],
  defiHoldingProtocolIds: [],
  typeList: []
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const placeholderText = computed(() => {
  return `${t('common.select')}...`
})

const deFiPositionsFilter = computed(() => {
  return (
    accountStore.deFiPositionsFilterList.find((item) => {
      return item.entityId === entityId.value
    })?.data ?? ({} as DeFiHoldingPositionParamsType)
  )
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item) => {
    return item.status === 'NORMAL' && item.platform?.isEvm
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      isCheck: false
    }
  })
})
const protocolOptions = computed(() => {
  return accountStore.deFiHoldingProtocolList.map((item) => {
    return {
      id: item.defiHoldingProtocolId,
      value: item.defiHoldingProtocolId,
      label: item.name,
      icon: item.logo ?? ''
    }
  })
})

const typeOptions = computed(() => {
  return accountStore.deFiHoldingTypeList.map((item) => {
    return {
      id: useId(),
      value: item,
      label: item
    }
  })
})

const accountDeletedOptions = computed(() => {
  // const accountList = accountStore.accountList
  //   .filter((item: any) => {
  //     return item.status !== 'NORMAL'
  //   })
  //   .map((item: any) => {
  //     return {
  //       id: item.entityAccountId,
  //       value: item.entityAccountId,
  //       label: item.name,
  //       icon: item.platform.logo,
  //       isCheck: false
  //     }
  //   })
  // if (accountList.length) {
  //   return [
  //     {
  //       title: t('title.deletedAccounts'),
  //       list: accountList
  //     }
  //   ]
  // }
  return []
})

const onClearData = (type: string) => {
  switch (type) {
    case 'type':
      filterData.value.typeList = []
      break
    case 'protocol':
      filterData.value.defiHoldingProtocolIds = []
      break
    case 'account':
      filterData.value.entityAccountIds = []
      break
    default:
      filterData.value = {
        entityAccountIds: [],
        defiHoldingProtocolIds: [],
        typeList: []
      }
      break
  }
}

const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
}

defineExpose({ onCheckDrawerStatus })

const onSave = async () => {
  try {
    saveLoading.value = true
    const newFilterData = JSON.parse(JSON.stringify(filterData.value))
    Object.keys(newFilterData).forEach((item: any) => {
      if (Array.isArray(newFilterData[item]) && !newFilterData[item].length) {
        delete newFilterData[item]
      } else if (!newFilterData[item]) {
        delete newFilterData[item]
      }
    })
    newFilterData.group = deFiPositionsFilter.value.group
    newFilterData.subGroup = deFiPositionsFilter.value.subGroup
    accountStore.editDeFiPositionsFilterList(entityId.value, newFilterData)
    ElMessage.success(t('message.success'))
    emit('onFilterData')
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

watch(
  () => isShowEditDrawer.value,
  () => {
    if (isShowEditDrawer.value) {
      nextTick(() => {
        if (!isEmpty(deFiPositionsFilter.value)) {
          filterData.value = {
            entityAccountIds: deFiPositionsFilter.value?.entityAccountIds ?? [],
            defiHoldingProtocolIds: deFiPositionsFilter.value?.defiHoldingProtocolIds ?? [],
            typeList: deFiPositionsFilter.value?.typeList ?? []
          }
        } else {
          onClearData('')
        }
      })
    }
  }
)
</script>

<style lang="scss" scoped>
.elv-defi-position-drawer-filter {
  .elv-defi-position-drawer-filter-header__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1e2024;
    margin: 0;
  }

  .elv-defi-position-drawer-filter-header-operating {
    display: flex;
    align-items: center;
  }

  .elv-defi-position-drawer-filter-header__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;

    span {
      display: block;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 6px;
      padding-right: 8px;
      border-right: 1px solid #dde1e6;
    }
  }

  .elv-defi-position-drawer-filter-header__close {
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-defi-position-drawer-filter {
  .el-drawer__header {
    padding: 10px 0px;
    margin: 0 24px;
    border-bottom: 1px solid #d0d4d9;
    height: 48px;
    box-sizing: border-box;
    display: flex;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .elv-transaction-drawer-filter-wrap {
    padding: 0px 24px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .elv-transaction-drawer-filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .elv-transaction-drawer-filter-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 4px;
      height: 24px;

      p {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: #5e85eb;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }
}

.el-select.is-unEmpty {
  .el-select__wrapper {
    border-color: #5e85eb;
    background-color: #f7f9fe;
    min-height: 44px;
    height: fit-content;

    &.is-hovering {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }

  .el-select__selected-item {
    display: flex;

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-select__tags-text {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.el-select.is-select-value {
  .el-select__wrapper {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 0px;
  }
}

.el-select.is-select-value-top {
  .el-select__wrapper {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
}
</style>
